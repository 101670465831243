import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

export default function Articles({ data }) {
    return (

< Layout >
<SEO 
          title="Articles"
          description={data.site.description}
/>
<div className="index">
<h2>All Posts</h2>
          {data.allMdx.edges.map(({ node }) => (
            <div className="index-article" key={node.id}>
              <Link to={node.fields.slug} >
                    <h3>
                        {node.frontmatter.title}{" "}
                    </h3>
                </Link>
                <p className="index-leadin">{node.frontmatter.leadin}</p>
                <span className="index-date">
                    — {node.frontmatter.date}
                  </span>
                
              
            </div>
          ))}

    <img src="../images/Acorn-Kawaii-83631.gif" alt="" />
</div>
</ Layout >

)
}


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allMdx(sort: { order: DESC, fields: [frontmatter___date] } ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            leadin
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`